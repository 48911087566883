// Fonts
@import url('https://fonts.googleapis.com/css?family=Nunito');

html body {
    font-size: 1rem;
    font-family: var(--sc-google-font)!important;
    color: var(--color-text)!important;
}

// Variables
@import 'variables';

@tailwind base;
@tailwind components;
@tailwind utilities;


.content{min-height: calc(100vh - 2.5rem)!important; background-color: #f1f5f9 !important; padding-left:15px!important; padding-right: 15px!important;}

.hyphens{hyphens: auto!important;}

.align-center{align-items: center;}

div[x-data="LivewireUIModal()"]{z-index: 9999999;}
div[x-data="LivewireUIModal()"] .w-full.bg-white{max-width: 50vw; width: initial}

.documents-grid .side-menu-item:hover,
.documents-grid .side-menu-item.active{background: #f1f5f9; color: var(--white)}

.side-nav{
    background: rgb(var(--color-menu-background))!important;
    .side-menu{
        color: rgb(var(--color-menu-text))!important;
        font-size: 15px!important;
        &:hover{
            background: #f1f5f9!important;
        }
        &.side-menu--active,
        ~:hover{
            background: rgb(var(--color-menu-active-background))!important;
            color: rgb(var(--color-menu-active-text))!important;
        }
    }
}


div[x-ref="popover"] {
    justify-content: flex-start;
}

@media (prefers-color-scheme: dark) {
    .dark\:text-white{color: $table-text-color !important;}
}

.data-table{
    table{
        border-collapse:separate;
        border-spacing:0 5px;
    }
    tbody{
        -webkit-border-vertical-spacing: 10px;
    }
    .filter_row td{
        background: rgb(var(--color-slate-100)/var(--tw-bg-opacity)) !important;
    }

}

@media only screen and (max-width: 767px) {
    .content{min-height: calc(100vh - 5rem) !important;}
    .mobile-menu {
        margin-inline: 0!important;
        .scrollable{width: 100vw; z-index: 120;}
    }
    body{padding-inline: 0!important; background: #f1f5f9!important;}
}

[id^=datatable-] {
    .shadow {
        box-shadow: none !important;
    }
    .id_col {
        text-align: left!important;
    }

    .th_center{
        text-align: center!important;
    }

    .td_center{
        text-align: center!important;
    }

    table {
        border-spacing: 0 5px;
        border-collapse: separate;

        > thead {
            background: transparent;
            th {
                background: transparent;
                text-align: left;
            }

            .uppercase {
                text-transform: unset;
                font-weight: initial;
                font-size: 13px;
                color: #333;
            }
        }

        > tbody {
            > tr {
                &:hover > td {
                    background: #e2e8f0;
                }

                > td {
                    background: #fff;
                    font-size: 13px!important;
                    * {
                        font-size: 13px!important;
                    }

                    &:first-child {
                        border-top-left-radius: 10px;
                        border-bottom-left-radius: 10px;
                    }

                    &:last-child {
                        border-top-right-radius: 10px;
                        border-bottom-right-radius: 10px;
                    }

                    &.actions_col {
                        padding-left: 0.5rem!important;
                        padding-right: 0.5rem!important;
                    }
                }
            }
        }

        > tfoot {
            tr {
                background: transparent!important;
                td {
                    background: transparent!important;
                }
            }
        }
    }
}

.mobile-menu {
    .scrollable {
        background: rgb(var(--color-menu-background)) !important;
        .menu {
            color: rgb(var(--color-primary) / var(--tw-text-opacity));
            &.menu--active {
                position: relative;
                background: rgb(241 245 249) !important;
                border-radius: 0.75rem;
                &::before{
                    content: "";
                    position: absolute;
                    top: 0px;
                    right: 0px;
                    bottom: 0px;
                    left: 0px;
                    display: block;
                    border-radius: 0.75rem;
                    border-bottom-width: 3px;
                    border-style: solid;
                    border-color: rgb(0 0 0 / 0.08);
                }
            }
        }
    }
}
.absolute:has(+input:disabled),
input:disabled + .absolute{
    display: none!important;
}

//.datatable.filters {
//    width:250px!important;
//    &.listbox{
//        ui-select {
//           ui-option > div {
//               display: none;
//           }
//        }
//        .listbox-selected-input {
//            input{
//                padding-right: 2rem!important;
//                text-overflow: ellipsis;
//            }
//        }
//    }
//}
